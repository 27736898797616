.add-icon-btn{
   color: #8C30F5;
   border:1px solid #8C30F5;
   font-weight: bold;
   font-size: 15px;
}
.change-link-btn{
   color: #8C30F5;
   font-size: 16px;
   border: 1px solid #8C30F5;
}
.links-container{
   border: 1px solid #494949;
   min-height: 120px;
   /* align-items: center; */
}
.three-dots-container{

border-right: 1px solid #494949;
}

.save-changes-btn{
   background-color: #8C30F5;
   border: 1px solid black;
   color: white;
   font-weight: 500;
}
.input-label{


   color: #4E4E4E;
   font-size: 20;
   font-family: 'Inter';
}
.delete-button{
   background-color: #F53030 !important;
}