.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
  margin-bottom: 30px;
}
.info-container .name {
  font-size: 18px;
  font-weight: 600;
}
.info-container .position {
  font-size: 14px;
  font-weight: 400;
}
.info-container .business-name {
  font-size: 10px;
  font-weight: 300;
}

.social-profiles-container {
  /* display: flex;
  align-items: center; */
  margin: 14px 0px 0px 0px;
}
.social-profiles-container .icon-name-container {
  display: flex;
  align-items: center;
}
.social-profiles-container .row-1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0px 8px 0px;
}
.social-profiles-container .row-2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0px 8px 0px;
}
.social-profiles-container .row-3 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0px 8px 0px;
}
.social-profiles-container .row-4 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0px 8px 0px;
}

.social-profiles-container .row-1 .icon-name-container .name {
  margin: 0px 0px 0px 8px;
}
.social-profiles-container .row-2 .icon-name-container .name {
  margin: 0px 0px 0px 8px;
}
.social-profiles-container .row-3 .icon-name-container .name {
  margin: 0px 0px 0px 8px;
}
.social-profiles-container .row-4 .icon-name-container .name {
  margin: 0px 0px 0px 8px;
}
.vist-website-container{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.vist-website-container a{
   border: 1px solid white;
   padding: 5px;
   border-radius: 5px;
}

.vist-website-container h2{
  border-bottom: 3px solid white;

}

.artilce-container{
  margin: auto;
  padding:0px 20px 0px 20px;
  word-wrap: break-word

}
.social-profiles-container .services-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 10px; */
  border: 2px solid #766bdb;
  border-radius: 5px;
  width: 95%;
  margin: auto;

}
.rotate-icon{
  transform: rotate(90deg);
}
.services-container .single-service{
  padding: 10px;
  border: 2px solid #766bdb;
  width: 100%;
  
}
.services-container .single-service .head{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 8px;
}
.services-container .single-service .head svg{
  cursor: pointer;
}
.work-secton{
  display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
flex-direction: column;
}
.work-secton video{
  width: 90%;
  margin-top: 10px;
}
.show-card-bg{
  max-height: 87vh;
  margin-top: -40px;
  
  margin-top:10px;
  border-radius: 38px;
  margin-right: 15px;
  margin-left: 8px;
  margin-top:10px;
  overflow-y: auto;
  max-width:89%;
}
.show-card-bg::-webkit-scrollbar{
  display: none;
}
.reviews-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    word-wrap: break-word;


}
.reviews-container p{
  word-wrap: break-word;
}
.reviews-container .review-name{
    margin-top: 10px;
    word-wrap: break-word;
    white-space: initial;
    font-size: 12px;
    margin: 3px 4px 3px 5px;
    text-align: center;

}
.reviews-container  h2{
  border-bottom: 2px solid white;
}
.lead-gen-title {
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
}

.gallary-images{
  display: flex;
  gap: 10px;
  flex-flow: wrap;
  
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;

}